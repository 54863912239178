#scheduleOperator {
  .dx-scheduler-date-table-cell,
  .dx-scheduler-time-panel-cell {
    height: 7.7vh;
  }

  // Colunas Desabilitadas
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    position: relative;
  }
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date,
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .break-time {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .disable-date,
  .break-time {
    height: 100%;
    width: 100%;
  }
  .disable-date {
    background-image: repeating-linear-gradient(
      135deg,
      #d45d2a38,
      #d45d2a38 4px,
      transparent 4px,
      transparent 9px
    );
    color: #9b6467;
  }

  // Tempo de intervalo
  .break-time {
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 13px;
    border-radius: 0;

    p {
      margin: 0;
    }
  }
  .dx-scheduler-header-panel-cell .disable-date,
  .dx-scheduler-header-panel-cell .break-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .booked-day-cell {
    border-radius: 16px;
    text-wrap: balance;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    color: #333;
    background-color: #ffd700;
  }

  #draggable-task,
  #appointment-task {
    border-radius: 10px 16px 16px 10px;
    padding: 12px 26px 12px;
    margin-bottom: 8px;
    position: relative;
    .left-bar {
      position: absolute;
      left: 0px;
      top: 0;
      width: 12px;
      height: 100%;
      border-radius: 16px;
    }
    .class-id {
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      line-height: 17.5px;
    }
    .card-text {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
    .updated-badge {
      .ant-badge-status-dot {
        background-color: #05005b;
      }

      .ant-badge-status-text {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  // Tarefas na agenda
  .dx-scheduler-appointment {
    background: transparent;
    box-shadow: none;

    .dx-scheduler-appointment-content {
      padding: 4px 0 3px 1px;
      opacity: 1;

      #appointment-task {
        padding: 2px 14px 2px;
        border-radius: 16px;
        height: 100%;
        margin: 0;
        color: #333;
        background-color: #f3f3f3;

        .top-bar {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 20px;
        }

        .class-id {
          position: absolute;
          top: 2px;
          font-size: 12px;
          line-height: 16.5px;
        }

        .body {
          margin-top: 22px;

          .card-text,
          .card-description {
            font-size: 11px;
            line-height: 14px;
          }

          .card-description {
            white-space: break-spaces;
            max-height: 54px;
            overflow: hidden;
          }

          .updated-tag {
            position: absolute;
            top: 4px;
            right: 2px;
            padding: 6px;
            border: 1px solid #fabc2c;
            background-color: #fabc2c;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #333;
          }

          .tags-1 {
            position: absolute;
            bottom: 30px;
            display: flex;
            align-items: center;

            .students-tag {
              padding: 6px;
              display: flex;
              align-items: center;

              p {
                margin-left: 2px;
              }
            }

            .instructor-tag {
              padding: 6px;
              display: flex;
              align-items: center;
            }
          }

          .tags-2 {
            position: absolute;
            bottom: 4px;
            display: flex;
            align-items: center;

            .stage-tag {
              background-color: white;
              border-radius: 8px;
              padding: 6px;
            }

            .shared-training-tag {
              border: 1px solid rgb(177, 177, 177);
              border-radius: 50%;
              height: 26px;
              width: 26px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  .dx-button-mode-contained.dx-buttongroup-first-item.dx-button,
  .dx-button-mode-contained.dx-buttongroup-first-item.dx-state-hover .dx-button-content,
  .dx-button-mode-outlined.dx-buttongroup-first-item.dx-button,
  .dx-button-mode-outlined.dx-buttongroup-first-item.dx-state-hover .dx-button-content {
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }

  .dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
  .dx-button-mode-contained.dx-buttongroup-last-item.dx-state-hover .dx-button-content,
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button,
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-state-hover .dx-button-content {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}
