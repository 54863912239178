.overview-popup {
  height: min-content;

  .dx-popup-content {
    padding: 0;
    overflow: auto;
  }
}

.overview-summary {
  background-color: #fafafa;

  td {
    font-weight: 600;
    font-size: 15px;
  }
}
