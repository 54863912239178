#scrollQuotes {
  max-height: calc(100vh - 122px);
  margin-top: 70px;

  .dx-scrollable-container {
    border-radius: 16px;
  }

  #quotesList {
    border-radius: 16px;

    .dx-empty-message {
      height: 32px;
      padding: 6px 12px;
    }

    .dx-accordion-item:first-child {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .dx-accordion-item:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    .dx-accordion-item {
      min-height: 37px;

      &.dx-state-focused {
        border-color: #05005b;
      }

      .dx-accordion-item-title {
        h2 {
          text-align: left;
          font-size: 14px;
          padding: 0;
          margin: 0;
          font-weight: bold;
        }
      }

      .dx-accordion-item-body {
        padding: 8px 0 8px;

        .dx-task-card {
          border-radius: 10px 16px 16px 10px;
          margin-bottom: 8px;
          position: relative;

          .task-content {
            padding: 12px 26px 12px;

            .left-bar {
              position: absolute;
              left: 0px;
              top: 0;
              width: 12px;
              height: 100%;
              border-radius: 16px;
            }

            .right-bar {
              flex-direction: column;
              align-items: center;
              position: absolute;
              right: 0px;
              top: 0;
              width: 30px;
              height: 100%;
              display: flex;
              padding: 7.5px 0;

              .anticon {
                height: fit-content;
                margin: 2.5px 0;
              }
            }

            .class-id {
              margin: 0;
              font-size: 13px;
              font-weight: bold;
            }

            .card-text {
              margin: 0;
              font-size: 12px;
              font-weight: 600;
            }
            .updated-badge {
              .ant-badge-status-dot {
                background-color: #05005b;
              }

              .ant-badge-status-text {
                font-size: 12px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .class-accordion {
      .dx-accordion-item-title {
        h2 {
          padding-left: 12px;
          color: #3a3a3a;
        }
      }

      .dx-accordion-item {
        border-radius: 0px;
        border-left: none;
        border-right: none;
        border-bottom: none;
        border-color: #ddd !important;
      }

      .dx-accordion-item-body {
        padding: 0 12px 0 24px;
      }
    }
  }
}

#QuotesFilter {
  .tooltip-globalfilter {
    margin: 0;
    height: 22px;
    margin-bottom: 0 !important;

    .ant-row {
      flex-direction: row;

      .ant-form-item-label,
      .ant-form-item-control {
        width: fit-content;
      }

      .ant-form-item-control-input {
        align-items: flex-start;

        .ant-form-item-control-input-content {
          display: flex;
          margin-left: 4px;
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;

    &:hover {
      border-color: #05005b !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: #05005b !important;
    box-shadow: 0 0 0 2px #05005b33;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-col-1 {
    align-items: center;
    margin-top: 10px;

    button {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
  }
}

.quote-list-skeleton {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .quote-list-skeleton-input,
  .quote-list-skeleton-input span {
    margin: 0;
    height: 15vh;
    border-radius: 16px;
  }
}
