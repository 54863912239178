$red: #05005b;
$redOpaque: #f1f1ff;

.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
  position: relative;
}

// Colunas Desabilitadas
.dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date,
.dx-scheduler-work-space-month .dx-scheduler-date-table-cell .break-time {
  position: absolute;
  width: 100%;
  height: 100%;
}

.disable-date,
.break-time,
.open-cell,
.filled-cell {
  height: 100%;
  width: 100%;
}

.disable-date {
  background-image: repeating-linear-gradient(
    135deg,
    #fc5e2062,
    #fc5e2062 4px,
    transparent 4px,
    transparent 9px
  );
  color: #1b1b1b;
}

// Tempo de intervalo
.break-time {
  background: #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
  border-radius: 16px;

  p {
    margin: 0;
  }
}

.dx-scheduler-header-panel-cell .disable-date,
.dx-scheduler-header-panel-cell .break-time,
.dx-scheduler-header-panel-cell .open-cell,
.filled-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sourceCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .indicator-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .indicator {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin: 5px 2.5px 5px 2.5px;
    }
  }
}

#draggable-task,
#appointment-task {
  border-radius: 10px 16px 16px 10px;
  padding: 12px 26px 12px;
  margin-bottom: 8px;
  position: relative;

  .left-bar {
    position: absolute;
    left: 0px;
    top: 0;
    width: 12px;
    height: 100%;
    border-radius: 16px;
  }

  .right-bar {
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    display: flex;
    padding: 7.5px 0;

    .anticon {
      height: fit-content;
      margin: 2.5px 0;
    }
  }

  .class-id {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 17.5px;
  }

  .card-text {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .updated-badge {
    .ant-badge-status-dot {
      background-color: #05005b;
    }

    .ant-badge-status-text {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

#scheduleTrainingCenter,
#scheduleScheduling {
  height: calc(100vh - 122px);

  .dx-scheduler-container {
    background: transparent;

    // Toolbar
    .dx-scheduler-header {
      background: transparent;
      border: none;
      height: 50px;

      .dx-toolbar .dx-toolbar-item-content {
        padding: 0;
        height: 32px;
        display: flex;
        align-items: center;

        .schedule-title {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: bold;
          color: #323232;
        }

        .schedule-ct-capacity {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          color: #323232;
        }

        .dx-buttongroup .dx-buttongroup-wrapper {
          height: 32px;

          .dx-scheduler-navigator-caption .dx-button-content {
            padding: 5px 18px 5px;
          }

          .dx-buttongroup-first-item {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;

            .dx-button-content {
              padding: 6px 8px;
            }
          }

          .dx-buttongroup-last-item {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;

            .dx-button-content {
              padding: 6px 8px;
            }
          }
        }
      }

      .dx-toolbar .dx-toolbar-before,
      .dx-toolbar .dx-toolbar-center,
      .dx-toolbar .dx-toolbar-after {
        height: fit-content;
      }

      .dx-toolbar .dx-toolbar-after .dx-button-content {
        padding: 5px 16px 5px !important;
        height: 30px;
      }
    }

    .dx-scheduler-work-space {
      background: transparent;
      border: none;

      // Header da agenda
      .dx-scheduler-header-panel-container {
        .dx-scheduler-header-panel-empty-cell {
          border: none;
          border: 0;
          margin: 0;
        }

        .dx-scheduler-header-tables-container {
          .dx-scheduler-header-panel {
            border: none;

            thead tr th {
              border: none;
            }
          }
        }
      }
    }
  }

  // Celulas da agenda
  .dx-scheduler-date-table-cell {
    border: 0;
    padding: 0 4px;
    width: 180px;
    height: 7.4vh;

    // Marcador Capacidade do Slot
    .ant-ribbon-wrapper {
      height: 100%;
      margin-bottom: 8px;

      .ant-ribbon {
        right: -4px;
        top: unset;
        bottom: 5px;
        z-index: 1;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        font-size: 12px;
        background-color: #05005b;
      }
    }

    // OPEN CELL
    .open-cell {
      background-color: #3bb856;
    }

    // FILLED CELL
    .filled-cell {
      background-color: white !important;
    }

    // BREAK TIME CELL
    .break-time {
      padding: 4px 0;
      height: 92%;
      z-index: 1;
      position: relative;
    }

    // Todas as Celulas
    .every-cell {
      position: relative;
    }

    // Linha da ultima celula da coluna
    .last-column-cell-line {
      height: 10vh;
      position: absolute;
      right: -5px;
    }

    // Celula de slot adicional
    .additional-slot {
      background-color: #2e8f82;
    }

    // Celula de slot vazio
    .invisible-cell {
      background-color: transparent;
    }

    // Celulas do topo de 1 slot
    .top-slot-cell {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      margin-top: 8px;
    }

    // Celulas do fundo de 1 slot
    .bottom-slot-cell {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      margin-bottom: 8px;
    }
  }

  // Celulas do cabeçalho da agenda
  .dx-scheduler-header-panel {
    margin: 0;

    .dx-scheduler-header-panel-cell {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border: 0;

      .disable-date,
      .open-cell,
      .filled-cell {
        background-image: none;
        background-color: transparent;
      }
    }
  }

  // Nome das pistas no cabeçalho da agenda
  .dx-scheduler-group-row {
    border: 0;

    .dx-scheduler-group-header {
      border: 0;
    }
  }

  // Divisão entre os dias na visão semanal
  .dx-scheduler-last-group-cell {
    border-right: 1px solid #bbbbbb !important;
  }

  // Coluna com as horas
  .dx-scheduler-sidebar-scrollable {
    display: none;
  }

  // Foco e Hover nas celulas
  .dx-scheduler-focused-cell,
  .dx-state-hover {
    box-shadow: none;
    background-color: transparent;
    color: #333;
  }

  // Tarefas na agenda
  .dx-scheduler-appointment {
    background: transparent;
    box-shadow: none;

    .dx-scheduler-appointment-content {
      padding: 4px 0 3px 1px;
      opacity: 1;

      #appointment-task {
        padding: 2px 14px 2px;
        border-radius: 16px;
        height: 100%;
        margin: 0;
        color: #333;
        background-color: #ffffff;

        .top-bar {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 20px;
        }

        .class-id {
          position: absolute;
          top: 2px;
          font-size: 12px;
          line-height: 16.5px;
        }

        .body {
          margin-top: 22px;

          .card-text,
          .card-description {
            font-size: 11px;
            line-height: 14px;
          }

          .card-description {
            white-space: break-spaces;
            max-height: 54px;
            overflow: hidden;
          }

          .updated-tag {
            position: absolute;
            top: 4px;
            right: 2px;
            padding: 6px;
            border: 1px solid #fabc2c;
            background-color: #fabc2c;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #333;
          }

          .tags-1 {
            position: absolute;
            bottom: 30px;
            display: flex;
            align-items: center;

            .students-tag {
              padding: 6px;
              display: flex;
              align-items: center;

              p {
                margin-left: 2px;
              }
            }

            .instructor-tag {
              padding: 6px;
              display: flex;
              align-items: center;
            }

            .client-tag {
              padding: 6px;
              display: flex;
              align-items: center;
            }
          }

          .tags-2 {
            position: absolute;
            bottom: 4px;
            display: flex;
            align-items: center;

            .stage-tag {
              background-color: white;
              border-radius: 8px;
              padding: 6px;
            }

            .shared-training-tag {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;
            }
          }
        }

        @media (max-width: 1440px) {
          .body {
            margin-top: 15px;

            .card-description {
              max-height: 28px;
              margin: 0;
            }

            .tags-1 {
              bottom: 24px;

              .students-tag {
                padding: 4px;
              }

              .instructor-tag {
                padding: 4px;
              }

              .clientßß-tag {
                padding: 4px;
              }
            }

            .tags-2 .stage-tag {
              border-radius: 6px;
              padding: 4px;
            }
          }
        }
      }
    }
  }
}

.dx-scheduler-appointment-collector.dx-button {
  background-color: $red !important;
  box-shadow: none !important;
}

.dx-scheduler-appointment-collector.dx-button.dx-state-hover::before {
  background-color: transparent !important;
}

.dx-scheduler-appointment-collector.dx-button.dx-state-active,
.dx-scheduler-appointment-collector.dx-button.dx-state-focused,
.dx-scheduler-appointment-collector.dx-button.dx-state-hover {
  background-color: #05005b !important;
}
.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected,
.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected .dx-icon {
  background-color: $redOpaque !important;
}
.dx-texteditor.dx-state-active.dx-editor-filled,
.dx-texteditor.dx-state-active.dx-editor-outlined,
.dx-texteditor.dx-state-active.dx-editor-underlined,
.dx-texteditor.dx-state-focused.dx-editor-filled,
.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-focused.dx-editor-underlined {
  border-color: $red !important;
}
.dx-texteditor.dx-state-hover {
  border-color: rgba($red, $alpha: 0.3) !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center !important;
}

// Fake Text Area Observação Negócio no modal de Treinamento
.deal-notes {
  display: block;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 16px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Helvetica Neue', 'Segoe UI', helvetica, verdana, sans-serif;
  line-height: 1.35715;
  padding: 7px 9px 8px;
  opacity: 0.5;
  min-height: 118px;
}

// Popup Treinamento
.task-popup {
  padding-top: 5px !important;
}

.ant-select-clear {
  margin-right: 3%;
}

.dx-popup-title .dx-toolbar-items-container .dx-toolbar-label {
  max-width: fit-content !important;
}
