$red: #05005b;
$redOpaque: #f1f1ff;

#schedulerOverview {
  .dx-scheduler-container {
    background: transparent;

    // Toolbar
    .dx-scheduler-header {
      background: transparent;
      border: none;

      .dx-toolbar .dx-toolbar-item-content {
        padding: 0;

        .dx-buttongroup .dx-buttongroup-wrapper {
          height: 32px;

          .dx-scheduler-navigator-caption .dx-button-content {
            padding: 5px 18px 5px;
          }

          .dx-buttongroup-first-item {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;

            .dx-button-content {
              padding: 6px 8px;
            }
          }

          .dx-buttongroup-last-item {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;

            .dx-button-content {
              padding: 6px 8px;
            }
          }
        }
      }

      .dx-toolbar .dx-toolbar-after .dx-button-content {
        padding: 5px 16px 5px !important;
      }
    }

    .dx-scheduler-work-space {
      background: transparent;
      border: none;

      // Header da agenda
      .dx-scheduler-header-panel-container {
        .dx-scheduler-header-panel-empty-cell {
          border: none;
        }

        .dx-scheduler-header-tables-container {
          .dx-scheduler-header-panel {
            border: none;

            thead tr th {
              border: none;
            }
          }
        }
      }

      // Body da agenda
      .dx-scheduler-work-space-flex-container {
        .dx-scheduler-sidebar-scrollable {
          .dx-scheduler-group-header {
            justify-content: center;
            align-items: center;
            border: none;
          }
        }

        .dx-scheduler-date-table-scrollable {
          .dx-scheduler-cell-sizes-vertical {
            width: 100px !important;
          }

          .dx-scheduler-date-table {
            tbody tr td {
              border: 0;
              border-radius: 16px;
              margin: 2.5px;
              cursor: pointer;

              &:hover {
                background: transparent;
                cursor: default;
              }
            }
          }

          .dx-scheduler-scrollable-appointments {
            .dx-scheduler-appointment {
              background-color: transparent;
              box-shadow: none;
              border: 0;

              &::before {
                content: none;
              }

              .dx-scheduler-appointment-content {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Borda das celulas
#schedulerOverview .dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell,
#schedulerOverview .dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

// Remove as horas de cima
#schedulerOverview
  .dx-scheduler-timeline-week
  .dx-scheduler-header-panel
  thead
  .dx-scheduler-header-row:last-child,
#schedulerOverview
  .dx-scheduler-timeline-day
  .dx-scheduler-header-panel
  thead
  .dx-scheduler-header-row:last-child {
  display: none;
}

.training-filter {
  .ant-select {
    width: 40%;
    margin-right: 5px;
  }
}

.overview-cell {
  color: rgb(49, 49, 49);
  font-size: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  &.blocked {
    background-image: repeating-linear-gradient(
      135deg,
      #fc5e2062,
      #fc5e2062 4px,
      transparent 4px,
      transparent 9px
    );
  }

  &.no-slot-configured {
    background-image: repeating-linear-gradient(
      135deg,
      #eccd1d62,
      #eccd1d62 4px,
      transparent 4px,
      transparent 9px
    );
  }
}
.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
  color: $red !important;
}
.dx-calendar-navigator-next-month.dx-button .dx-icon,
.dx-calendar-navigator-next-view.dx-button .dx-icon {
  color: $red !important;
}
.dx-calendar-navigator-previous-month.dx-button .dx-icon,
.dx-calendar-navigator-previous-view.dx-button .dx-icon {
  color: $red !important;
}
.dx-calendar-cell.dx-calendar-selected-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
  box-shadow: inset 0 0 0 1000px $red !important;
}
.dx-calendar-cell.dx-state-hover {
  color: $red !important;
}

// filtro
.dx-tagbox-popup-wrapper .dx-list-select-all {
  display: none;
}
.dx-dropdownlist-popup-wrapper .dx-empty-message,
.dx-dropdownlist-popup-wrapper .dx-list-item {
  display: flex;
  align-content: flex-end;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}
.dx-checkbox-checked.dx-checkbox-icon {
  border: none;
}
.dx-checkbox-icon {
  border: none !important;
  background-color: transparent !important;
}
.dx-list-item-before-bag .dx-list-select-checkbox,
.dx-list-item-before-bag .dx-list-select-radiobutton {
  margin-left: -3px !important;
}
.dx-item.dx-list-item.dx-state-focused.dx-list-item-selected {
  background-color: $redOpaque;
  font-weight: 500;
}
.dx-item.dx-list-item.dx-list-item-selected {
  background-color: $redOpaque;
  font-weight: 500;
}
.dx-toolbar-item-content > .dx-texteditor {
  border-radius: 16px !important;
  height: 32px !important;
}
.dx-tag-content {
  background-color: rgba(221, 221, 221, 0.226) !important;
  border-radius: 16px !important;
  margin-top: 2px !important;
}
.dx-placeholder {
  position: absolute;
  margin-left: 6px !important;
  margin-top: -2px !important;
}
