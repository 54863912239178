#resource-card {
  background-color: transparent;
  border-color: #b7b7b7;
  border-radius: 16px;
  margin-bottom: 10px;

  .ant-card-extra {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .ant-form-item {
      margin: 0;
    }
  }

  /* Hide autocomplete dropdown */
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}
