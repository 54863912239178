.versionModal {
  text-align: start;

  .subtitle {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .symbol {
      margin-right: 10px;
    }
  }

  .bulletList {
    margin-left: 40px;

    li {
      list-style-type: disc;
    }
  }
}
