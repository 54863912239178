.training-card-form {
  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-input-disabled,
  .ant-select-disabled .ant-select-selector,
  .ant-picker-disabled,
  .ant-picker-disabled input,
  .ant-input-number-disabled,
  .ant-input-number-disabled input {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important;
    cursor: default !important;
  }

  .upload-file-field-no-data {
    opacity: 0.4;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-select-disabled .ant-select-arrow,
  .ant-picker-disabled .ant-picker-suffix {
    display: none;
  }

  .ant-form-item-label label {
    font-size: 12px;
    color: #a3a9b1;
  }

  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 15px !important;
    color: #424956 !important;
    cursor: default !important;
  }

  .task-form-section-card {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 0 15px;
    background-color: #f9fafb;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    transition: background-color 250ms linear, opacity 250ms linear;

    .ant-card-body,
    .ant-card-head {
      background-color: transparent;
      border-radius: 0;
    }

    .ant-card-head {
      padding: 0 !important;
    }

    .ant-card-body {
      padding: 15px 0 !important;
    }
    .ant-card-head .ant-card-head-wrapper {
      border-bottom: 1px solid #e8ecee;

      .ant-card-head-title {
        font-weight: 600;
        font-size: 13px;
        color: #525c69;
      }
    }
  }
}
