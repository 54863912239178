#schedule-disable-dates {
  .cell-selected {
    box-shadow: inset 0 0 0 1px #05005b !important;
    background-color: #05005b26 !important;
  }
  .dx-scheduler-container {
    .dx-scheduler-work-space {
      .dx-scheduler-scrollable-appointments {
        .dx-scheduler-appointment {
          background-color: transparent;
          box-shadow: none;
          border: 0;
          width: 0px !important;
          height: 0 !important;

          &::before {
            content: none;
          }

          .dx-scheduler-appointment-content {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            padding: 0;
          }
        }
      }
    }
  }
}

.schedule-modal {
  .ant-modal-footer {
    margin-top: -20px;
  }
}
#appointments-blocked-days {
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 100%;
  top: -20px;
  left: 5px;
  background-color: #fc5d20;
}

.selected-cell {
  border: 1px solid #ec0b0b;
}

.appointment-booking {
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 100%;
  top: -20px;
  left: 5px;

  &.booking {
    background-color: #ffd700;
  }
  &.training {
    background-color: #3bb856;
  }
  &.blocked {
    background-color: #fc5d20;
  }
}

.instructor-booking-selected-date-list {
  border: none !important;
  .ant-list-items .ant-divider {
    margin: 5px 0;
  }
}
