.overview-resource-cell {
  display: flex;
  flex-direction: column;
  width: 100%;

  .resource-cell-ct-name {
    font-size: 13px;
    font-weight: bold;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    hyphens: auto;
  }

  .resource-cell-info {
    display: flex;
    width: 100%;
    gap: 10%;

    span {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
