.training-card-drawer {
  .ant-drawer-title {
    font-size: 26px;
    color: #333;
    font-weight: 400;
  }

  .ant-drawer-body {
    background-color: #eef2f4;
    padding: 23px 15px 21px 21px;
  }

  .ant-drawer-header,
  .ant-drawer-footer {
    background-color: #fff;
  }

  .ant-drawer-header {
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  }

  .ant-drawer-footer {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  }
}
