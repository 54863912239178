.transport-list-item-wrapper {
  padding: 8px 12px;
  border-radius: 16px;
  background-color: white;
  width: 100%;
  position: relative;

  p {
    margin-bottom: 2px;
  }

  h3,
  p {
    width: fit-content;
  }

  .transport-list-item-delete-btn {
    color: #fc5d20;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
