.new-slot-popup .dx-popup-content {
  overflow: auto;

  .anticon.anticon-delete svg {
    cursor: pointer;
  }

  .new-slot-footer {
    display: flex;
    margin-top: 10px;

    button:first-child {
      color: green;
      border: 1px solid #d9d9d9;

      &:hover {
        border-color: green;
        color: green;
      }

      &:disabled {
        border: 1px solid #d9d9d9;
        color: rgba(0, 0, 0, 0.25);

        &:disabled .anticon-check-circle {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    button:last-child {
      color: #fc5d20;
      border: 1px solid #d9d9d9;

      &:hover {
        border-color: #fc5d20;
        color: #fc5d20;
      }

      &:disabled,
      &:disabled .anticon-delete {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
