#tasks-list {
  .accept-button {
    background-color: #05005b;
  }
  .dx-item.dx-list-item.dx-state-focused,
  .dx-item.dx-list-item.dx-state-active {
    background-color: #f1f1ff !important;
    color: #05005b;
  }
  .dx-item.dx-list-item {
    background-color: #f0f2f5;
    color: black;
  }
}
