.notifications-drawer {
  z-index: 9999999 !important;

  .ant-drawer-body .ant-list-item {
    border-color: #d7d7d7;
  }

  .ant-drawer-title {
    text-align: center;
  }
}

.notification-wrapper {
  h2 {
    font-size: 14px;
    color: black;
    font-weight: 600;
    padding: 0;
    text-align: left;
  }

  .notification-body {
    font-size: 13px;
    margin-bottom: 0;
  }

  .notification-body {
    color: #424242;
  }

  .notification-date {
    font-size: 12px;
    font-weight: 600;
    color: #7c7c7c;
  }
}
