.changeVisibility {
  button,
  button:hover,
  button:focus {
    box-shadow: none !important;
    font-size: 22px;
    padding-top: 0;
    float: right;
    width: 60px;
    background-color: #05005b;
    box-shadow: 0px 4px 4px #00000040 !important;
  }
}
