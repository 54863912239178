#Billing {
  .tooltip-globalfilter {
    margin: 0;
    height: 22px;
    margin-bottom: 0 !important;

    .ant-row {
      flex-direction: row;

      .ant-form-item-label,
      .ant-form-item-control {
        width: fit-content;
      }

      .ant-form-item-control-input {
        align-items: flex-start;

        .ant-form-item-control-input-content {
          display: flex;
          margin-left: 4px;
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;

    &:hover {
      border-color: #05005b !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: #05005b !important;
    box-shadow: 0 0 0 2px #05005b33;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-col-1 {
    align-items: center;
    margin-top: 10px;

    button {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
  }
}

#billing-list {
  margin-top: 10px;
  height: 450px;

  .dx-list-item-before-bag .dx-list-select-checkbox {
    margin-left: 0 !important;
  }
}

.ant-picker-panel-container {
  border-radius: 16px !important;
}
.dx-checkbox-checked .dx-checkbox-icon {
  color: #05005b !important;
}
