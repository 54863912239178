.screen-success {
  position: absolute;
  top: 40%;
  left: 30%;
  margin: -25px 0 0 -25px;
  font-size: 14px;
  padding: 16px;
  border-radius: 16px;
  background-color: #ffff;
  box-shadow: 0px 4px 4px #00000040;

  p {
    line-height: 5px;
  }

  h1 {
    font-size: 18px;
  }
}
.screen-success-mobile {
  width: 82%;
  top: 32%;
  left: 15%;
  font-size: 12px;
  padding: 15px;
  border-radius: 16px;
  background-color: #ffff;
  box-shadow: 0px 4px 4px #00000040;
}

.wrapper-login-mobile {
  background-color: #f2f4f5;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  h2 {
    display: none;
  }
  .right-panel {
    height: 270px !important;
  }
  .left-panel {
    background-color: #f2f4f5 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
  }

  .wrapper-form {
    height: 200px !important;
  }
}
