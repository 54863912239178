h2 {
  text-align: center;
  padding-bottom: 20px;
}
.resources {
  border-radius: 16px !important;
  box-shadow: 0px 4px 4px #00000040;
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;

  .ant-card-body {
    align-content: center;
    margin-top: -10px;
  }

  .ant-card-head-title {
    text-align: center;
  }

  .ant-col-8 {
    margin-left: auto;
    margin-right: auto;
  }

  .fields-permissions {
    height: 32px;
  }
}
