.user-register-upload {
  .ant-upload-select,
  .ant-upload-select img {
    border-radius: 50% !important;
    height: 150px !important;
    width: 150px !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
