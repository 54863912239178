.wrapper-login {
  height: 100vh;

  .left-panel {
    background-color: #ffffff;
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.56);
    background-image: url(../../Assets/Images/back-login-new.jpg);
    background-size: cover;
    background-position: center;
  }

  .right-panel .wrapper-form {
    height: 100vh;
    background-color: #f3f3f3;

    form {
      width: 50%;

      h2 {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 40px;
      }
    }
  }
}
