.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.button-new {
  background-color: #05005b !important;
  border-color: #05005b !important;
  color: white !important;
  width: 100px;
  height: 32px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.ant-table-wrapper {
  border-radius: 16px;
  box-shadow: 0 0 18px 2px #c7c7c7;

  .ant-table-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .ant-table-body {
    .ant-table-row {
      td.ant-table-cell-fix-right {
        padding: 0;
        cursor: pointer;

        .ant-dropdown-trigger {
          height: 47px;
          font-size: 38px;
          line-height: 22px;
          color: #05005b;
        }
      }
    }

    .table__inactive-row {
      opacity: 0.4;
      background: #dddddd;

      td {
        border-right-color: #bfbfbf !important;
        border-left-color: #bfbfbf !important;
        background: #dddddd !important;
      }
    }
  }

  .ant-table,
  .ant-table-container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.ant-pagination-item,
.ant-pagination-prev button,
.ant-pagination-next button,
.ant-table-filter-dropdown,
#inputSearch,
.ant-dropdown-menu {
  border-radius: 16px !important;
}

.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 5px;
  border-radius: 0 0 16px 16px;
}

// dropdown antd
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  cursor: pointer;
}

.ant-dropdown-menu-item:first-child {
  border-radius: 16px 16px 0 0;
}

.ant-dropdown-menu-item:last-child {
  border-radius: 0 0 16px 16px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #05005b !important;
}

.ant-pagination-item-active a {
  color: #05005b !important;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:hover a,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #05005b !important;
  color: #05005b !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e0e0e0 !important;
}
