.filterComponent {
  font-size: 20px;
  cursor: pointer;
}

.custom-popover {
  z-index: 1000 !important;
  padding: 0 !important;

  .ant-popover-placement-top {
    top: 60px !important;
    left: 250px !important;
  }

  .ant-popover-inner-content {
    padding: 0px 0px !important;
    margin-top: 10px !important;
  }

  .ant-form-vertical {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
  }
}

.task-list-table .ant-table-pagination.ant-pagination {
  padding: 10px 5px 10px 5px;
}
