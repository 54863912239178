.certificate-table {
  .ant-table-pagination.ant-pagination {
    padding: 10px 5px 10px 5px;
  }

  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 0;
    }

    .ant-table-wrapper {
      border-radius: 0;
      box-shadow: none;
    }
  }
}
