#task-activities {
  .ant-timeline-item {
    padding: 0;

    .ant-timeline-item-content {
      margin: 0 0 0 34px;
    }

    .ant-timeline-item-content {
      .ant-card-body {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        padding: 12px 16px 12px 16px !important;
        overflow: hidden;
        box-sizing: border-box;
      }
    }

    .ant-timeline-item-tail {
      border-color: #dfe0e3;
      border-width: 1px;
      top: 20.5px;
      height: 300px;
    }
  }

  .ant-card {
    border-radius: 10px;
    font-size: 14px;
    margin-bottom: 10px;

    .ant-card-head {
      padding: 8px 50px 10px 15px;
      display: flex;
      min-height: 36px;

      .ant-card-head-title {
        padding: 0;
        font-size: 13px;
      }
    }

    .ant-card-body {
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: #333;
      }
    }
  }

  .task-activity-date-wrapper {
    display: flex;
    justify-content: center;

    .task-activity-date-tag {
      display: flex;
      background-color: #dde5e8;
      color: #6e7273;
      border-radius: 11px;
      padding: 2px 15px;
      margin: 0;
      font-size: 12px;
    }
  }

  .task-activity-title-wrapper {
    font-size: 13px !important;
    color: #bdc1c6 !important;

    span {
      font-weight: 600;
      margin-right: 8px;
      color: #a8adb4 !important;
    }
  }

  .ant-timeline-item-head {
    background-color: #fff;
    border-color: #fff;
    border-radius: 50%;
    top: 16px;
    color: #bdc1c6;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.task-activity-date-wrapper {
  margin-bottom: 16px;
}

.task-activity-date-tag {
  font-weight: bold;
  margin-bottom: 8px;
}

.task-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.task-comment p {
  margin: 0;
}

.task-comment-username {
  margin-top: -5px !important;
}
