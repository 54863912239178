.wrapper-privacy-policy {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: #f3f3f3;

  .download-app-header {
    background: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .privacy-policy-body {
    padding: 0 50px 50px 50px;

    p {
      width: 100%;
    }
  }
}
