.user-dropdown {
  position: absolute !important;
  right: 24px;
  top: 3.5px;
  cursor: pointer;
}

.btn-new-version {
  position: absolute !important;
  right: 58px;
  top: 3.5px;
  height: 26px !important;
  width: 26px !important;
}

.site-layout-background {
  padding: 0 24px 0 24px;
  margin: 0;
  min-height: 280px;

  .ant-avatar-circle {
    width: 26px;
    height: 26px;
  }
}

.notification-badge .ant-badge-count {
  height: 17px;
  min-width: 17px;
  width: 17px;
  font-size: 10px;
  line-height: 15px;
  left: -4px;
  top: -3px;
  padding: 0;
}
