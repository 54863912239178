body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

::selection {
  background-color: #05005b63;
}

a {
  color: #05005b;

  &:hover {
    color: #140e7a;
  }
}

.custom-a:hover {
  text-decoration: underline;
}

// DROPDOWN
.ant-dropdown .ant-dropdown-menu {
  .ant-dropdown-menu-item,
  span div button {
    cursor: pointer;
  }
}

// INPUT
.ant-input-number.ant-input-number-in-form-item {
  border-radius: 16px;
  width: 162.33px;
  box-shadow: 0px 4px 4px #00000040;
  width: 100%;
}
.ant-input,
.ant-select-selector,
.ant-input-password {
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 16px;
}
.input-hidden {
  display: none;
}
.ant-input-password input {
  box-shadow: none;
  border-radius: none;
}
.ant-input-affix-wrapper-disabled,
.ant-input-number-disabled {
  box-shadow: none !important;
}
.ant-input:hover {
  border-color: #05005b;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #05005b;
  box-shadow: 0 0 0 2px #05005b33;
}
.ant-input-number:hover {
  border-color: #05005b;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #05005b;
  box-shadow: 0 0 0 2px #05005b33;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #05005b;
}
.ant-input-wrapper .ant-input-group-addon {
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;

  button {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

// INPUT COM AFFIX
.ant-input-affix-wrapper {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 16px;
  height: 32px;

  input {
    border-radius: 0;
    box-shadow: none;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #05005b;
  }

  &:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #05005b;
    box-shadow: 0 0 0 2px #05005b33;
  }
}

// Upload Input
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #05005b;
}

// PICKER
.ant-picker.ant-picker-range {
  border-radius: 16px;
  box-shadow: 0px 4px 4px #00000040;
  width: 100%;
}
.ant-picker {
  box-shadow: 0px 4px 4px #00000040;
  width: 100%;
}
.ant-picker-disabled {
  box-shadow: none !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #05005b;
  box-shadow: 0 0 0 2px #05005b33;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #05005b;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #05005b;
}
.ant-picker-today-btn {
  color: #05005b;

  &:hover,
  &:focus {
    color: #140e7a;
  }
}
.ant-picker-header-view button:hover {
  color: #140e7a;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #f1f1ff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #ccccff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: #05005b !important;
}
.ant-picker-range .ant-picker-active-bar {
  background-color: #05005b !important;
}

.ant-picker-cell-inner::after {
  background: #ccccff !important;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  border-color: #05005b;
  color: #05005b;
  background: #f1f1ff;
}

// CARD
.ant-card-body {
  padding: 10px !important;
}
.ant-card-extra {
  font-size: 15px !important;
}

// SELECT
.ant-select-disabled .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-selector {
  border-radius: 16px !important;
}
.ant-select-multiple .ant-select-selection-item {
  border-radius: 16px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #05005b;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: 0 0 0 2px #05005b33;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f1f1ff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #05005b;
}

// FORM
.ant-form-item .ant-form-item-label {
  padding: 0;
}
.form-checkbox {
  .ant-checkbox-wrapper {
    width: max-content;
  }

  .ant-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item-control {
      max-width: 18px;
      margin-right: 12px;
    }
  }
}
.form-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    min-width: 100px;
    max-width: 100px;
  }
}

// CHECKBOX
.ant-checkbox-inner {
  height: 18px;
  width: 18px;

  &::after {
    left: 22.5%;
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #05005b !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #05005b;
}

// BUTTON
.ant-btn {
  border-radius: 16px;
  box-shadow: 0px 4px 4px #00000040;
}

//  AntD BTN Primary
.ant-btn-primary {
  border-color: #05005b;
  background-color: #05005b;

  &:hover,
  &:focus {
    border-color: #140e7a;
    background-color: #140e7a;
  }
}

.ant-btn-default {
  &:hover,
  &:focus {
    border-color: #140e7a;
    color: #140e7a;
  }
}

// AntD BTN Link
.ant-btn-link {
  color: #05005b;

  &:hover,
  &:focus {
    color: #140e7a;
  }
}

// AntD BTN Dashed
.ant-btn-dashed {
  &:hover,
  &:focus {
    color: #140e7a;
    border-color: #05005b;
  }
}

// AntD BTN Dangerous
.ant-btn-dangerous:hover {
  color: #ff834a;
  border-color: #ff834a;
}

// AntD BTN Radio
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: #05005b !important;
  border-color: #05005b;
}
.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-color: #05005b;
}
.ant-radio-button-wrapper:hover {
  color: #05005b;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0px 4px 4px #00000040;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #05005b;
}

// TOP LOADER
.global-spinner {
  display: none !important;
  flex-direction: row-reverse;
  color: #05005b !important;
  position: fixed !important;
  top: 7px;
  right: 94px;
  z-index: 999999;

  .ant-spin-text {
    margin-right: 14px;
  }

  .ant-spin-dot-item {
    background-color: #05005b;
  }
}
.loading-indicator .global-spinner {
  display: flex !important;
}

// AntD Spin
.ant-spin {
  color: #05005b;
}

// SKELETON
.ant-skeleton {
  margin: 16px;
  width: 82%;
}

// MODAL
.ant-modal-wrap {
  .ant-modal-content {
    border-radius: 16px;
    background-color: #f0f2f5;

    .ant-modal-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-color: #f0f2f5;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row-reverse;

      button {
        min-width: 120px;
      }
    }
  }
}

// POP AGENDAMENTO TREINAMENTO
.dx-scheduler-appointment-popup {
  .dx-popup-normal {
    max-width: 70vw !important;
  }
}

// TOOLTIP
.ant-tooltip {
  z-index: 9999;
}
.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  border-radius: 16px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  left: 1px;
}

// POPUP DEVEXPRESS
.dx-popup-normal {
  border-radius: 16px !important;
  .dx-toolbar {
    background-color: #f0f2f5;
  }

  .dx-popup-content {
    background-color: #f0f2f5;
    border-radius: 16px !important;

    .dx-textbox,
    .dx-numberbox {
      box-shadow: 0px 4px 4px #00000040;
      border-radius: 16px;

      &.dx-state-hover {
        border-color: #05005b;
      }

      &.dx-state-focused {
        border-color: #05005b;
        box-shadow: 0 0 0 2px #05005b33;
        outline: 0;
      }

      .dx-dropdowneditor-icon {
        background-color: transparent;
      }
    }
  }
}

.dx-overlay-content {
  border-radius: 16px !important;
}

// ANTD MESSAGE
.ant-message {
  z-index: 2000;
}

// ANTD INPUT NUMBER
.ant-input-number-handler-wrap {
  right: 12px;

  .ant-input-number-handler {
    border: 1px solid #d9d9d9;
  }
}

// ANTD NOTIFICATION
.ant-notification {
  z-index: 9999999 !important;

  .ant-notification-notice {
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 12px;

    .ant-notification-notice-message {
      font-size: 14px;
      margin-bottom: 0;
      margin-left: 36px;
      color: black;
      font-weight: 600;
    }

    .ant-notification-notice-description {
      font-size: 12px;
      margin-left: 36px;
      line-height: 16px;
      font-weight: 600;
      color: #424242;
    }

    .ant-notification-notice-close {
      top: 12px;
      right: 12px;
    }
  }
}

// Title quando passa o mouse em cima de algo (para quando não é possível utilizar o Tooltip do Antd)
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.3s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  opacity: 0;
  position: absolute;
  top: -40px;
  right: 0;
  z-index: 99999;
  white-space: nowrap;
  visibility: hidden;
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
[data-title] {
  position: relative;
}

// Devexpress Botão
.dx-button {
  border-radius: 16px;
}

// Devexpress Checkbox
.dx-checkbox-icon {
  border: 1px solid #05005b !important;
}

// Devexpress Agenda (Cores dos efeitos)
.dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell,
.dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
  -webkit-box-shadow: inset 0 0 0 1px #05005b !important;
  box-shadow: inset 0 0 0 1px #05005b !important;
}
.dx-scheduler-all-day-table-cell.dx-state-hover.dx-state-focused,
.dx-scheduler-date-table-cell.dx-state-hover.dx-state-focused {
  background-color: #f1f1ff !important;
}
.dx-scheduler-all-day-table-cell.dx-state-focused,
.dx-scheduler-date-table-cell.dx-state-focused {
  background-color: #f1f1ff !important;
  opacity: 1;
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
  color: #05005b !important;
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell::before {
  background-color: #05005b !important;
}
.dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell {
  color: #05005b !important;
}
.dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell::before {
  background-color: #05005b !important;
}

// Devexpress Popup (Cores)
.dx-overlay-content.dx-popup-normal.dx-popup-flex-height.dx-resizable.dx-popup-draggable {
  background-color: #f0f2f5 !important;
}
.dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
  background-color: #f0f2f5 !important;
}

// cor do fundo do dropdown
.dx-popup-wrapper > .dx-overlay-content {
  background: #f1f1ff !important;
}

// Mudar a cor dos ponteiros do relogio
.dx-timeview-hourarrow {
  background-image: url('../Assets/Images/ponteiro_menor.svg');
}
.dx-timeview-minutearrow {
  background-image: url('../Assets/Images/ponteiro_maior.svg');
}
// Cor dos itens clicados no dropdown
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
  background-color: #05005b !important;
  color: #fff;
}
.dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item.dx-state-focused.dx-list-item-selected {
  background-color: rgba(#05005b, 0.7) !important;
  color: #fff;
}

// AntD Tab
.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #05005b;
}
.ant-tabs-ink-bar {
  background-color: #05005b;
}

// Scroll do DevExtreme
.dx-scrollable-scroll-content {
  background-color: rgb(132, 125, 125) !important;
}

// AntD Popover
.ant-popover {
  z-index: 9999;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent !important;
}

/* Remove default border and shadows */
.dx-tabpanel > .dx-tabpanel-container > .dx-multiview-wrapper,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab,
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tabs-expanded .dx-tab:not(.dx-tab-selected) {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

/* Custom tab styles */
.dx-tab {
  background-color: transparent !important;
  font-weight: 600;
  width: 140px;
}

/* Custom selected and active tab styles */
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-active .dx-tab-content,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
  color: #181169 !important;
  border-bottom: 2px solid #181169;
  padding-bottom: 5px;
}
