.training-list-confirmation-modal,
.training-list-columns-settings-modal,
.certificate-upload-students-modal,
.training-list-deletion-modal,
.training-list-attach-files-modal,
.certificate-settings-modal,
.generate-certificate-modal {
  .ant-modal-header .ant-modal-title {
    text-align: start !important;
    font-size: 18px;
  }

  .ant-modal-body p {
    text-align: start;
  }

  .ant-modal-content .ant-modal-footer {
    justify-content: end !important;
    flex-direction: row-reverse !important;
    gap: 8px;
  }

  .certificate-upload-students-footer-wrapper,
  .generate-certificate-footer-wrapper {
    display: flex;
    justify-content: space-between !important;
  }
}

.training-list-deletion-modal {
  min-width: 26%;
}

.training-list-columns-settings-modal .ant-modal-body {
  text-align: start;
}

.certificate-upload-students-modal,
.generate-certificate-modal {
  .file-panel {
    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-header-text {
      text-align: start;
    }
  }

  .row-panel {
    .ant-collapse-content-box {
      padding: 16px !important;
    }

    .ant-collapse-header-text {
      text-align: start;
    }
  }
}
