$red: #05005b;
$redOpaque: #f1f1ff;

.default-file-manager.dx-widget.dx-filemanager {
  border: none;
  .dx-drawer-wrapper {
    border-radius: 16px;
    background-color: #f0f2f5 !important;

    .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
      background: #f0f2f5 !important;
    }
    .dx-filemanager-toolbar {
      background: #f0f2f5 !important;
    }
    .dx-filemanager-toolbar .dx-filemanager-toolbar-separator-item {
      display: none !important;
    }
    .dx-toolbar .dx-toolbar-items-container {
      background: #f0f2f5 !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-left.dx-sort-indicator {
      color: black !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-left {
      color: black !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-right {
      color: black !important;
    }
    .dx-row.dx-header-row {
      background: #f0f2f5 !important;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
      background-color: $redOpaque !important;
      color: $red !important;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td {
      border-top: 1px solid $redOpaque !important;
      border-bottom: 1px solid $redOpaque !important;

      .dx-button-content .dx-icon {
        color: $red;
      }
    }
    .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
      border-color: $red !important;
    }
    .dx-checkbox.dx-state-hover .dx-checkbox-icon {
      border: 1px solid $red !important;
    }
    .dx-checkbox.dx-state-focused .dx-checkbox-icon {
      border: 1px solid $red !important;
    }
    .dx-checkbox-checked .dx-checkbox-icon {
      color: $red !important;
    }
    .dx-checkbox-indeterminate .dx-checkbox-icon::before {
      background-color: $red !important;
    }
    .dx-progressbar-range {
      border: 1px solid $red !important;
      background-color: $red !important;
    }
    .dx-datagrid-table
      .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(
        .dx-edit-row
      ):not(.dx-row-focused)
      > td:not(.dx-focused) {
      background: #f0f2f5 !important;
      color: $red !important;
    }
    .dx-filemanager-dirs-tree.dx-treeview
      .dx-treeview-item-without-checkbox
      > .dx-treeview-item.dx-filemanager-focused-item {
      background: $redOpaque !important;
      color: $red !important;
    }
    .dx-treeview-item.dx-state-hover {
      background-color: rgba(0, 0, 0, 0) !important;
      color: $red !important;
    }
    .dx-filemanager-dirs-tree.dx-treeview .dx-state-focused > .dx-treeview-item * {
      color: $red !important;
    }
    .dx-menu-item.dx-state-hover {
      color: $red !important;
      background-color: $redOpaque !important;
    }
    .dx-button-mode-text.dx-state-hover {
      background-color: $redOpaque !important;
      color: $red !important;
    }
    .dx-button-mode-text .dx-icon {
      &:hover {
        color: $red !important;
      }
    }
    .dx-filemanager-thumbnails .dx-filemanager-thumbnails-item.dx-state-focused {
      border: 1px solid $red !important;
    }
    .dx-filemanager
      .dx-filemanager-thumbnails
      .dx-filemanager-thumbnails-item.dx-item-selected.dx-state-focused {
      background: $redOpaque !important;
      color: $red !important;
    }
    .dx-filemanager
      .dx-filemanager-thumbnails
      .dx-filemanager-thumbnails-item.dx-state-hover:not(.dx-state-focused):not(.dx-item-selected) {
      background: #f0f2f5 !important;
      color: $red !important;
    }
    .dx-filemanager-thumbnails .dx-filemanager-thumbnails-item.dx-item-selected {
      color: $red !important;
      background: $redOpaque !important;
    }
    .dx-filemanager-files-view {
      background: #f0f2f5 !important;
    }
    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
      background-color: $redOpaque !important;
    }
    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
      background-color: $redOpaque !important;
      color: #fff;
    }
    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
      background-color: $red !important;
    }
    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
      background-color: $redOpaque !important;
      color: $red !important;
    }
  }
}

// Estilo do Mobile
.mobile-file-manager.dx-widget.dx-filemanager {
  border: none;

  .dx-drawer-wrapper {
    border-radius: 0;
    background-color: #f2f4f5 !important;

    .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
      background: #f2f4f5 !important;
    }
    .dx-filemanager-toolbar {
      background: #f2f4f5 !important;
    }
    .dx-filemanager-toolbar .dx-filemanager-toolbar-separator-item {
      display: none !important;
    }
    .dx-toolbar .dx-toolbar-items-container {
      background: #f2f4f5 !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-left.dx-sort-indicator {
      color: black !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-left {
      color: black !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-right {
      color: black !important;
    }
    .dx-row.dx-header-row {
      background: #f2f4f5 !important;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
      background-color: $redOpaque !important;
      color: $red !important;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td {
      border-top: 1px solid $redOpaque !important;
      border-bottom: 1px solid $redOpaque !important;

      .dx-button-content .dx-icon {
        color: $red;
      }
    }
    .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
      border-color: $red !important;
    }
    .dx-checkbox.dx-state-hover .dx-checkbox-icon {
      border: 1px solid $red !important;
    }
    .dx-checkbox.dx-state-focused .dx-checkbox-icon {
      border: 1px solid $red !important;
    }
    .dx-checkbox-checked .dx-checkbox-icon {
      color: $red !important;
    }
    .dx-checkbox-indeterminate .dx-checkbox-icon::before {
      background-color: $red !important;
    }
    .dx-progressbar-range {
      border: 1px solid $red !important;
      background-color: $red !important;
    }
    .dx-datagrid-table
      .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(
        .dx-edit-row
      ):not(.dx-row-focused)
      > td:not(.dx-focused) {
      background: #f2f4f5 !important;
      color: $red !important;
    }
    .dx-filemanager-dirs-tree.dx-treeview
      .dx-treeview-item-without-checkbox
      > .dx-treeview-item.dx-filemanager-focused-item {
      background: $redOpaque !important;
      color: $red !important;
    }
    .dx-treeview-item.dx-state-hover {
      background-color: rgba(0, 0, 0, 0) !important;
      color: $red !important;
    }
    .dx-filemanager-dirs-tree.dx-treeview .dx-state-focused > .dx-treeview-item * {
      color: $red !important;
    }
    .dx-menu-item.dx-state-hover {
      color: $red !important;
      background-color: $redOpaque !important;
    }
    .dx-button-mode-text.dx-state-hover {
      background-color: $redOpaque !important;
      color: $red !important;
    }
    .dx-button-mode-text .dx-icon {
      &:hover {
        color: $red !important;
      }
    }
    .dx-filemanager-thumbnails .dx-filemanager-thumbnails-item.dx-state-focused {
      border: 1px solid $red !important;
    }
    .dx-filemanager
      .dx-filemanager-thumbnails
      .dx-filemanager-thumbnails-item.dx-state-hover:not(.dx-state-focused):not(.dx-item-selected) {
      background: #f2f4f5 !important;
    }
    .dx-filemanager-files-view {
      background: #f2f4f5 !important;
    }
    .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
      background-color: $redOpaque !important;
      color: #fff;
    }
  }
}
