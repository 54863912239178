.instructor-preregister .ant-card-type-inner,
.instructor-preregister-wrapper .ant-card-type-inner {
  margin-top: 25px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  box-shadow: 0px 4px 4px #00000040;
  padding: 0 20px 0 20px;
  background-color: #fafafa;

  .ant-card-body {
    background-color: #fafafa;
    border-radius: 16px;
  }
  .ant-card-head {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .ant-card-head-title {
    font-size: 17px;
  }
}

.layout-wrapper .ant-layout-header {
  background: #f0f2f5;
  padding: 0 12px;
}

.instructor-preregister-wrapper .ant-card-body {
  background-color: #f0f2f5;
}

.layout-wrapper {
  h1 {
    text-align: center;
    margin-top: -50px;
  }
  .ant-card-head {
    text-align: center;
  }
}

.wrapper-mobile {
  .form-toolbar {
    display: none;
  }
  .instructor-preregister .ant-card-type-inner,
  .instructor-preregister-wrapper .ant-card-type-inner {
    background-color: #f2f4f5;
    box-shadow: none;
    border: none;

    .ant-card-body {
      background-color: #f2f4f5;
      border-radius: 16px;
    }
    .ant-card-head {
      background-color: #f2f4f5;
    }
    .ant-card-head-title {
      font-size: 17px;
    }
  }

  .instructor-preregister-wrapper .ant-card-body {
    background-color: #f2f4f5;
  }
}
