.siderBar-menu {
  button,
  button:hover,
  button:focus {
    background: none;
    border: none;
    color: black;
    box-shadow: none !important;
    font-size: 22px;
    height: 36px;
    padding-top: 0;

    &::after {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 6px;
    left: 49px;
    height: 26px;
  }

  .background-menu {
    background-color: #fff;
    border: 1px solid #05005b;
    border-left: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: calc(100vh - 36px);
    overflow: hidden;

    aside,
    .ant-menu {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      .ant-menu-item-selected {
        color: #05005b;
        background-color: #f1f1ff;
      }
    }

    .ant-menu-item:hover {
      color: #05005b;
    }
  }
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-color: #05005b !important;
}

.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #515151;
}
