.wrapper-app-download {
  display: flex !important;
  flex-direction: column !important;
  padding: 0 24px;

  .ant-carousel {
    display: flex;
    justify-content: center;
    margin: 16px 0 16px 0;
  }

  .download-app-header {
    background: transparent !important;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-left: 0;
    align-items: center;

    img {
      width: 300px;
      text-align: center;
    }
  }

  .download-app-alert-text {
    font-size: 16px;
    text-align: center;
  }

  .download-buttons {
    h2 {
      margin: 0;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: center;
      gap: 15px;

      .btn-app {
        width: 150px;
        transition: width 0.1s ease;

        &:hover {
          width: 155px;
          cursor: pointer;
        }
      }
    }
  }
}
