.resume-summary-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 20%;
}

.resume-popup-table .ant-table-wrapper {
  box-shadow: none;
  border-radius: 0;
}
