.custom-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons {
    .deny-button {
      margin-left: 10px;
    }
  }
}
