.appointment-tooltip-task {
  padding: 6px 35px 0px 8px !important;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  color: #333;
  background-color: #ffffff;
  margin-bottom: 1px !important;
  height: 120px;

  .top-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20px;
  }

  .class-id {
    position: absolute;
    top: 4px;
    font-size: 12px;
    line-height: 16.5px;
  }

  .body {
    margin-top: 22px;

    .card-text,
    .card-description {
      font-size: 11px;
      line-height: 14px;
    }

    .card-description {
      white-space: break-spaces;
      max-height: 54px;
      overflow: hidden;
      text-align: start;
    }

    .updated-tag {
      position: absolute;
      top: 4px;
      right: 2px;
      padding: 6px;
      border: 1px solid #fabc2c;
      background-color: #fabc2c;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #333;
    }

    .tags-1 {
      position: absolute;
      bottom: 30px;
      display: flex;
      align-items: center;

      .students-tag {
        padding: 6px;
        display: flex;
        align-items: center;

        p {
          margin-left: 2px;
        }
      }

      .instructor-tag {
        padding: 6px;
        display: flex;
        align-items: center;
      }

      .client-tag {
        padding: 6px;
        display: flex;
        align-items: center;
      }
    }

    .tags-2 {
      position: absolute;
      bottom: 4px;
      display: flex;
      align-items: center;

      .stage-tag {
        background-color: white;
        border-radius: 8px;
        padding: 6px;
      }

      .shared-training-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
      }
    }

    .wrapper-action {
      position: absolute;
      right: 0px;
      top: 0;
      width: 45px;
      padding-top: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .reschedule-icon,
      .delete-icon {
        width: 20px;
        margin-bottom: 8px;
      }
      .delete-icon {
        color: #d9534f;
      }
      .reschedule-icon {
        color: #7f51d4;
      }
    }
  }

  @media (max-width: 1440px) {
    .body {
      margin-top: 15px;

      .card-description {
        max-height: 28px;
        margin: 0;
      }

      .tags-1 {
        bottom: 24px;

        .students-tag {
          padding: 4px;
        }

        .instructor-tag {
          padding: 4px;
        }

        .clientßß-tag {
          padding: 4px;
        }
      }

      .tags-2 .stage-tag {
        border-radius: 6px;
        padding: 4px;
      }
    }
  }
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
  .dx-popup-content
  .dx-list-item
  .dx-list-item-content,
.dx-scheduler-overlay-panel .dx-popup-content .dx-list-item .dx-list-item-content {
  padding: 0 !important;
}

.dx-overlay-wrapper,
.dx-overlay-wrapper *,
.dx-overlay-wrapper ::after,
.dx-overlay-wrapper ::before,
.dx-overlay-wrapper::after,
.dx-overlay-wrapper::before {
  z-index: 999 !important;
}
